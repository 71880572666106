import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Message Type', 'messageType', true, 'desc', 'left'),
  new TableColumn('QDE Status', 'qdeStatus', true, 'desc', 'left'),
  new TableColumn('Message No', 'messageNo', true, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNo', true, 'desc', 'right'),
  new TableColumn('Created Date', 'createdDate', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
